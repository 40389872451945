<template>
  <v-main class="pt-0 mt-8">
    <main>
      <div class="main">
        <div class="big-area">
          <!-- left 卡片 -->
          <div>
            <div class="class-out-title">
              {{ $t("content.callOutHomePage") }}
            </div>
            <div class="platform-page mt-5">
              {{ $t("content.platformPage") }}
            </div>
            <div class="platform-page mt-3">
              <a href="https://www.facebook.com/profile.php?id=61557282451201&mibextid=LQQJ4d" target="_blank">
                <v-img src="@/assets/img/platform.png" max-width="350px" />
              </a>
            </div>
            <div class="call-out-content">
              <v-img :src="require('@/assets/img/message-out.svg')" max-width="50px" max-height="50px" class="mr-4" />
              (852) 6148 6870 | (853) 6666 1644
            </div>
            <div class="call-out-content d-flex align-center justify-start mb-4 wow animate__animated">
              <v-img :src="require('@/assets/img/weChat.svg')" max-width="50px" max-height="50px" class="mr-4" />
              LuckTraining
            </div>
            <div class="call-out-content">
              <v-img :src="require('@/assets/img/message-email.svg')" max-width="50px" max-height="50px" class="mr-4" />
              info@66.training
            </div>
            <div style="margin-top: 50px">
              <div class="apply-now-title">
                {{ $t("content.applyNow") }}
              </div>
              <div class="mt-5 belong-title">
                {{ $t("content.belongsYourInstitution") }}
              </div>

              <div class="mt-5">
                <div class="submit-button" html-type="submit" @click="goTo('leave_message')" style="display: flex; justify-content: start">
                  <a-button>
                    {{ $t("content.appointmentDemonstration") }}
                  </a-button>
                </div>
              </div>
            </div>
          </div>
          <!-- rigth 卡片 -->
          <div class="leave-message-area">
            <div class="leave-message-title">
              {{ $t("content.leaveMessage") }}
            </div>
            <div class="leave-message-title-set">
              {{ $t("content.leaveYourContactInformation") }}
            </div>
            <!-- 表單 -->
            <div style="margin-top: 20px">
              <a-form-model layout="vertical" ref="ruleForm" :model="formData" :rules="rules" @submit="onSubmit()">
                <div class="basic">
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item name="username" prop="userName" :label="$t('field.name').charAt(0).toUpperCase() + $t('field.name').slice(1)">
                        <a-input class="input-content-input" v-model="formData.userName" :placeholder="$t('field.name')" />
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item
                            name="gender"
                            :label="$t('content.whatDoWeCallYou')"
                          >
                            <a-select
                              class="input-content-input"
                              v-model="formData.gender"
                              style="width: 100%"
                              :placeholder="$t('content.whatDoWeCallYou')"
                            >
                              <a-select-option :value="1">
                                {{ $t("field.sir") }}
                              </a-select-option>
                              <a-select-option :value="2">
                                {{ $t("field.miss") }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </div>
                      </div> -->
                  <!-- 邮箱地址 -->
                  <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item :label="$t('field.email')">
                            <a-input class="input-content-input" v-model="formData.mail" :placeholder="$t('field.email')" />
                          </a-form-model-item>
                        </div>
                      </div> -->
                  <!-- 地區 -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item prop="areaCode" :label="$t('field.region').charAt(0).toUpperCase() + $t('field.region').slice(1)">
                        <a-select class="input-content-input" v-model="formData.areaCode" style="width: 100%" :placeholder="$t('field.region')">
                          <a-select-option v-for="(item, i) of phoneNumberList" :key="item.country_id" :value="'+' + item.country_code">
                            {{ item.country_name_en }}
                            + {{ item.country_code }}
                          </a-select-option>
                        </a-select>
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- telphone -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item prop="tel" :label="$t('field.contactNumber')">
                        <a-input class="input-content-input" :placeholder="$t('field.contactNumber')" default-value="mysite" v-model="formData.tel" :max-length="9" type="number"> </a-input>
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- code -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item prop="code" :label="$t('field.code')">
                        <a-input v-model="formData.code" :placeholder="$t('field.code')" class="input-content-input" style="height: 40px; font-size: 20px !important">
                          <div slot="addonAfter" @click="!loading ? sendCode() : ''">
                            <span class="send-text">
                              {{ !loading ? $t("field.sendCode") : `${surplusSecond}s` }}
                            </span>
                          </div>
                        </a-input>
                      </a-form-model-item>
                    </div>
                  </div>

                  <!-- companyName -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item prop="companyName" :label="$t('field.yourOrganizationName')">
                        <a-input class="input-content-input" v-model="formData.companyName" :placeholder="$t('field.yourOrganizationName')" />
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- companyAddress -->
                  <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item :label="$t('field.companyAddress')">
                            <a-input class="input-content-input" v-model="formData.companyAddress" :placeholder="$t('field.companyAddress')" />
                          </a-form-model-item>
                        </div>
                      </div> -->
                  <!-- youIdentityIs -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item prop="youIdentityIs" :label="$t('field.youIdentityIs')">
                        <a-radio-group class=" " style="display: flex; flex-wrap: wrap" v-model="formData.youIdentityIs" @change="onChange">
                          <a-radio :value="$t('field.institutionalLegalRepresentative')">
                            {{ $t("field.institutionalLegalRepresentative") }}
                          </a-radio>
                          <a-radio :value="$t('field.administrativeStaff')">
                            {{ $t("field.administrativeStaff") }}
                          </a-radio>
                          <a-radio :value="$t('field.staff')">
                            {{ $t("field.staff") }}
                          </a-radio>
                          <a-radio :value="$t('field.other')">
                            {{ $t("field.other") }}
                          </a-radio>
                        </a-radio-group>
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- interested -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item :label="$t('field.interested')" required prop="trainingType">
                        <a-checkbox-group v-model:value="formData.trainingType" class="w-full" style="width: 100%">
                          <div class="checkbox-training-type-options">
                            <a-checkbox v-for="(item, index) of trainingTypeOptions" :key="index" :value="item.label">
                              {{ item.label }}
                            </a-checkbox>
                          </div>
                        </a-checkbox-group>
                      </a-form-model-item>
                    </div>
                  </div>
                  <!-- invitationCode -->
                  <div class="user info">
                    <div class="input-content flex" style="display: flex">
                      <a-form-model-item name="invitationCode" prop="invitationCode" style="" :label="$t('content.invitationCode').charAt(0).toUpperCase() + $t('content.invitationCode').slice(1)">
                        <a-input class="input-content-input" v-model="formData.invitationCode" :placeholder="$t('content.invitationCode')" />
                      </a-form-model-item>
                    </div>
                  </div>
                  <div class="contact-details info">
                    <div class="input-content flex" style="display: flex; padding-bottom: 0 !important"></div>
                  </div>
                </div>

                <div class="input-content flex confirmContent" style="display: flex">
                  <a-form-model-item required prop="confirmContent">
                    <a-checkbox-group class="input-content-input" v-model:value="formData.confirmContent" style="width: 100%">
                      <div class="checkbox-training-type-options">
                        <a-checkbox v-for="(item, index) of confirmContentOptions" :key="index" :value="item.label">
                          {{ item.label }}
                        </a-checkbox>
                      </div>
                    </a-checkbox-group>
                  </a-form-model-item>
                </div>
                <div>
                  <a-form-item>
                    <div class="flex justify-center submit-button" @click="onSubmit()" html-type="submit" style="display: flex; justify-content: center">
                      <a-button> {{ $t("field.submit") }}</a-button>
                    </div>
                  </a-form-item>
                </div>
              </a-form-model>
            </div>
          </div>
        </div>
      </div>
      <sucess-dialog :openDialog="openDialog" :theName="formData.userName" @close="close" v-if="openDialog"></sucess-dialog>
    </main>
  </v-main>
</template>
<script setup>
import { createAnnouncementContext, useAnnouncementContext } from "../useLeaveMessage";

const inject = useAnnouncementContext();
</script>
<script>
import foote from "@/components/common/Footer01";
import navigation from "@/components/common/NavigationBar";
import contentImg from "@/components/common/contentImg";
import { message } from "ant-design-vue";
import axios from "axios";
import { useCountdown } from "@/hooks/web/useCountdown";
import { useRoute } from "vue-router";
import phoneNumberList from "@/hooks/phoneNumber";
import sucessDialog from "../components/sucessDialog.vue";

const { surplusSecond, start: startCountdown, loading } = useCountdown(60);
export function isMacaoTel(phone) {
  return /^(\+853\s)?(6\d{3}(\s)?\d{4})$/.test(phone);
}
export function isHongKongTel(phone) {
  return /^(\+852\s)?[569]\d{3}\s?\d{4}$/.test(phone);
}
export function isChinaTel(phone) {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(phone);
}

export default {
  name: "App",
  components: {
    navigation,
    contentImg,
    foote,
    surplusSecond,
    loading,
    useRoute,
    sucessDialog,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    surplusSecond,
    phoneNumberList,
    loading,
    codeType: "",
    formData: {
      userName: "",
      gender: undefined,
      areaCode: undefined,
      tel: "",
      region: "",
      mail: "",
      consultingService: "",
      message: "",
      companyName: "",
      companyAddress: "",
      trainingMethod: [],
      trainingType: [],
      studentNumber: "",
      nature: "",
      youIdentityIs: undefined,
      contactYouWay: [],
      info: false,
      confirmContent: [],
      invitationCode: "",
    },
    openDialog: false,
  }),
  computed: {
    rules() {
      let _this = this;
      let rules = {
        userName: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.name"),
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.contactNumber"),
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let isPass = false;

              switch (this.formData.areaCode) {
                case "+853":
                  isPass = isMacaoTel(this.formData.tel);
                  break;
                case "+852":
                  isPass = isHongKongTel(this.formData.tel);
                  break;
                case "+86":
                  isPass = isChinaTel(this.formData.tel);
                  break;
                case "":
                  isPass = false;
                  break;
                default:
                  isPass = true;
                  break;
              }
              if (isPass || value == "") {
                callback();
              } else {
                // callback(new Error(_this.$t("field.pleaseEnter") + _this.$t("field.contactNumber")));
                callback(new Error(_this.$t("content.pleaseConfirmPhoneNumberCorrect")));
              }
              return isPass;
            },
          },
        ],
        areaCode: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.region"),
            trigger: "change",
          },
        ],
        companyName: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.yourOrganizationName"),
            trigger: "blur",
          },
        ],
        trainingType: [
          {
            required: true,
            message: _this.$t("field.selectAtLeastOne"),
            trigger: "change",
          },
        ],
        confirmContent: [
          {
            required: true,
            message: _this.$t("content.pleaseCheck"),
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.code"),
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              // 假设你有一个名为 checkCode 的方法来检查验证码是否失败
              // 这里假设 checkCode 方法返回一个 Promise
              _this
                .checkCode()
                .then((res) => {
                  if (res.data.code != 200) {
                    // 验证码失败时，调用 callback 并传入错误消息
                    callback(new Error("验证码错误"));
                  } else {
                    // 验证码通过时，调用 callback 但不传入参数
                    callback();
                  }
                })
                .catch((error) => {
                  // 处理校验失败的情况
                  callback(new Error("校验失败：" + error.message));
                });
            },
            trigger: "submit",
          },
        ],
        contactYouWay: [
          {
            required: true,
            message: _this.$t("field.selectAtLeastOne"),
            trigger: "change",
          },
        ],
        youIdentityIs: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.messageyYouIdentityIs"),
            trigger: "change",
          },
        ],
      };
      return rules;
    },
    consultingServiceOptions() {
      let _this = this;
      let consultingServiceOptions = [
        { label: _this.$t("content.productDoorConsultationService"), value: 0 },
        { label: _this.$t("content.callForProductDetails"), value: 1 },
        { label: _this.$t("content.wantInformationQuestions"), value: 2 },
        {
          label: _this.$t("content.onSiteProductTrainingIsRequired"),
          value: 3,
        },
      ];
      return consultingServiceOptions;
    },
    studentNumberOptions() {
      let _this = this;
      let studentNumberOptions = [
        { label: "20~100", value: 0 },
        { label: "100~500", value: 1 },
        { label: "500~1000", value: 2 },
        { label: _this.$t("content.moreThan1000"), value: 3 },
      ];
      return studentNumberOptions;
    },
    trainingTypeOptions() {
      let _this = this;
      let trainingTypeOptions = [
        { label: _this.$t("field.leleTrainingParenthesis"), value: 0 },
        { label: _this.$t("field.leleMallParenthesis"), value: 1 },
        { label: _this.$t("field.leleMarketingService"), value: 2 },
        { label: _this.$t("field.leleAgent"), value: 3 },
      ];
      return trainingTypeOptions;
    },
    confirmContentOptions() {
      let _this = this;
      let trainingTypeOptions = [
        {
          label: _this.$t("content.whatIHaveFilledInIsTrueAndValid"),
          value: 0,
        },
      ];
      return trainingTypeOptions;
    },
    contactYouWayOptions() {
      let _this = this;
      let contactYouWayOptions = [
        { label: _this.$t("field.callMe"), value: 0 },
        { label: _this.$t("field.whatsapp"), value: 1 },
        { label: _this.$t("field.SMS"), value: 2 },
      ];
      return contactYouWayOptions;
    },
    characterOptions() {
      let _this = this;
      let characterOptions = [
        { label: _this.$t("field.personalTrainers"), value: 0 },
        { label: _this.$t("field.studioTrainingRoom"), value: 1 },
        { label: _this.$t("field.clubs"), value: 2 },
        {
          label: _this.$t("field.trainingInstitutionsWithLessThan10Teachers"),
          value: 3,
        },
        {
          label: _this.$t("field.largeInstitutionsWithMoreThan10FacultyMembers"),
          value: 4,
        },
        { label: _this.$t("field.chainInstitutions"), value: 5 },
      ];
      return characterOptions;
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
    this.info = true;

    // 要解密的Base64编码的字符串
    var encodedString = "bnZpdGF0aW9uQ29kZT1TYWxlczAx";

    // 使用atob()函数解密Base64编码的字符串
    var decodedString = atob(encodedString);

    console.log(decodedString); // 输出解密后的字符串
    const useRoute = this.$route;
    console.log(111, useRoute.query.invitationCode, this.$route);
    if (useRoute.query.invitationCode) {
      this.formData.invitationCode = atob(useRoute.query.invitationCode);
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },

  methods: {
    onChange() {
      console.log(989);
    },
    getTelphone() {
      return this.formData.areaCode + " " + this.formData.tel;
    },
    sendCode() {
      let hasError = false;
      // 注意事项：校验多项时，第一个参数传数组，回调函数会触发多次
      this.$refs.ruleForm.validateField(["tel", "areaCode"], (valid) => {
        // 有值就会判断
        if (valid) {
          hasError = true;
          return;
        }
      });
      if (!hasError) {
        // 不存在校验不通过的项，请求探测接口...
        console.log("不存在校验不通过的项，请求探测接口");

        // 后面是接口
        let phone = encodeURIComponent(this.getTelphone());
        let _this = this;
        axios({
          method: "get",
          url: `https://66.training/training_api/base/base/verification-code/getCode/${phone}?client=1`,
        }).then(function (response) {
          if (response.data.code == 200) {
            startCountdown();
            _this.codeType = response.data.data.type;
            console.log(22323, _this.codeType, response);
          }
        });
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },

    close() {
      this.openDialog = false;
      location.reload();
    },
    checkCode() {
      let _this = this;
      let phone = encodeURIComponent(this.getTelphone());
      return axios({
        method: "get",
        url: `https://66.training/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
      });
    },
    onSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let _this = this;
          let phone = encodeURIComponent(this.getTelphone());
          axios({
            method: "get",
            url: `https://66.training/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
          }).then(function (response) {
            console.log(1111, response);
            if (response.data.code == 200) {
              const data = {
                companyAddress: _this.formData.companyAddress,
                companyName: _this.formData.companyName,
                email: _this.formData.mail,
                gender: _this.formData.gender,
                // telphone: `${formData.value.telAreaCode} ${formData.value.tel}`,
                telphone: _this.getTelphone(),
                username: _this.formData.userName,
                // functionOption: ["答复"]

                functionOption: _this.formData.trainingType,
                invitationCode: _this.formData.invitationCode,
                role: _this.formData.youIdentityIs,
                contactYouWay: _this.formData.contactYouWay,
              };
              axios({
                method: "post",
                url: "https://66.training/training_api/base/base/send/mail/sendMailToUs",
                data: data,
              }).then(function (response) {
                if (response.data.code == 200) {
                  // message.success("提交成功!");
                  // setTimeout(function () {
                  //   location.reload();
                  // }, 2000);
                  _this.openDialog = true;
                }
              });
            } else {
              message.error(response.data.message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    goTo(url) {
      this.$router.push({
        path: `/${url}`,
      });
    },
  },
};
</script>
<style>
/* * {
  font-family: MicrosoftYaHei, MicrosoftYaHei !important;
} */
.v-application ul {
  padding-left: 0 !important;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-input-group-addon {
  padding: 0 !important;
}

::v-deep .has-error .ant-input-group-addon {
  border-color: #ffffff !important;
}

::v-deep .ant-input-group-addon {
  border-color: #ffffff !important;
}

.submit-button {
  .ant-btn {
    min-width: 200px;
    padding: 0 20px;
    height: 40px;
    background: #fe880a;
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    border-radius: 33px;
    font-size: 20px;
    // font-family: MicrosoftYaHei;
    color: #ffffff;
    border: none;

    &:after {
      box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    }
  }
}

.v-application .info {
  background-color: #fff !important;
}

.header {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .welcome {
    font-size: 15px;
    margin-top: 5px;
  }
}

@media (max-width: 640px) {
  main {
    // padding: 50px 300px;
    .main {
      padding: 30px 24px 30px;

      .info {
        .title {
          display: flex;
          align-items: baseline;
          font-size: 17px !important;
          word-break: break-all;
          font-weight: bold;
          color: #333333;

          &:before {
            margin-right: 6px;
            display: block;
            content: " ";
            width: 4px;
            height: 16px;
            background: linear-gradient(65deg, #f8d97f 0%, #ff8c65 100%);
            border-radius: 2px;
          }
        }

        .input-content {
          flex-direction: column;
          min-height: 50px;
          .input-content-input {
            height: 100%;
            min-height: 40px;
            line-height: 40px;
            font-size: 20px;

            border: 1px solid #d9d9d9;
            border-radius: 5px;
          }

          .ant-form-item {
            &:last-child {
              margin-right: 0;
            }

            &-label {
              > label {
                font-size: 16px;
                // font-family: MicrosoftYaHei;
                color: #666666;
                line-height: 21px;
              }
            }
          }

          .hidden.placeholder {
            display: none;
          }
        }
        .open-test-account {
          margin-top: 20px;

          .label {
            font-size: 16px;
            color: #666666;
          }
        }

        ::v-deep .ant-input {
          background: #f2f2f2;
          border-radius: 4px;
          border: none;
          //height: 56px;

          &:focus {
            box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
          }
        }

        ::v-deep textarea {
          &.ant-input {
            height: auto;
          }
        }

        ::v-deep .ant-select {
          &-selector {
            background: #f2f2f2;
            border-radius: 4px;
            border: none;
            //height: 56px;
          }

          &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
            border-color: red;
          }
        }

        .anticon-caret-down {
          font-size: 16px;
          color: #333333;
        }

        ::v-deep .ant-radio {
          &-group {
            display: flex;
            justify-content: start;
            padding-right: 30px;
          }
        }

        ::v-deep .ant-checkbox {
          &-group {
            display: flex;
            justify-content: start;
            width: 100%;
            padding-right: 30px;
          }
        }

        ::v-deep .ant-checkbox-group {
          width: 100%;
          padding-right: 0;
        }

        .checkbox-training-type-options {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 16px 0;

          ::v-deep .ant-checkbox-wrapper {
            margin-left: 8px;
          }
        }

        .confirmContent {
          .checkbox-training-type-options {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 16px 0;

            ::v-deep .ant-checkbox-wrapper {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 640px) {
  main {
    .big-area {
      display: flex;
      justify-content: space-between;
    }
    .main {
      padding: 65px 30px;

      .info {
        .title {
          display: flex;
          align-items: center;

          font-size: 20px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;

          font-weight: bold;
          color: #333333;

          &:before {
            margin-right: 6px;
            display: block;
            content: " ";
            width: 4px;
            height: 16px;
            background: linear-gradient(65deg, #f8d97f 0%, #ff8c65 100%);
            border-radius: 2px;
          }
        }

        .input-content {
          // 所有表单的item上下距离
          margin-top: 15px;

          ::v-deep .ant-form-item {
            flex: 1;
            margin-right: 168px;

            &:last-child {
              margin-right: 0;
            }

            &-label {
              > label {
                font-size: 16px;
                font-family: MicrosoftYaHei;
                color: #666666;
                line-height: 21px;
              }
            }
          }
        }

        .open-test-account {
          margin-top: 25px;

          .label {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #666666;
          }
        }
      }

      .basic,
      .message,
      .other {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      ::v-deep .ant-input {
        background: #f2f2f2;
        border-radius: 4px;
        border: none;

        &:focus {
          box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
        }
      }

      ::v-deep textarea {
        &.ant-input {
          height: auto;
        }
      }

      ::v-deep .ant-select {
        .ant-select-selection {
          background: #f2f2f2;
          border-radius: 4px;
          border: none;
        }

        .ant-select-selector {
          background: #f2f2f2;
          border-radius: 4px;
          border: none;
        }

        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
          box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
          border-color: red;
        }
      }

      .anticon-caret-down {
        font-size: 16px;
        color: #333333;
      }

      ::v-deep .ant-checkbox-group {
        width: 100%;
      }

      .checkbox-training-type-options {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px 0;

        ::v-deep .ant-checkbox-wrapper {
          margin-left: 8px;
        }
      }

      .confirmContent {
        .checkbox-training-type-options {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 16px 0;

          ::v-deep .ant-checkbox-wrapper {
            margin-left: 8px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  main {
    padding: 0px 250px;
  }
}

.send-text {
  color: #f58848;
  cursor: pointer;
}

// new 01
.platform-page {
  font-family: MicrosoftYaHei;
  font-size: 21px;
  color: #666666;
  text-align: left;
  font-style: normal;
}

.call-out-content {
  font-family: MicrosoftYaHei;
  font-size: 24px;
  color: #2d3f50;
  line-height: 45px;
  text-align: left;
  font-style: normal;
  display: flex;
  margin-top: 20px;
}

.over-companies {
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 36px;
  color: #2d3f50;
  line-height: 47px;
  text-align: left;
  font-style: normal;
}

.institutional-trust {
  font-family: MicrosoftYaHei;
  font-size: 36px;
  color: #2d3f50;
  line-height: 47px;
  text-align: left;
  font-style: normal;
}
@media (min-width: 960px) {
  .class-out-title {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 40px;
    color: #2d3f50;
    line-height: 66px;
    text-align: left;
    font-style: normal;
  }
  .institutional-trust-area {
    width: 600px;
    height: 246px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-top: 200px;
    .institutional-imgs {
      max-height: 80px;
    }
  }
  .leave-message-area {
    width: 500px;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-left: 50px;
    padding: 20px 20px;
  }
  .leave-message-title-set {
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #666666;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    margin-top: 10px;
  }
  .apply-now-title {
    font-family: MicrosoftYaHei;
    font-size: 50px;
    color: #2d3f50;
    line-height: 79px;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 36px;
    color: #2d3f50;
    line-height: 47px;
    text-align: left;
    font-style: normal;
  }
}
@media (max-width: 959.999px) {
  .class-out-title {
    font-size: 35px;
    color: #2d3f50;
    text-align: left;
    font-weight: 800;
    font-style: normal;
    margin-top: 50px;
  }
  .institutional-trust-area {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-top: 20px;
    .over-companies,
    .institutional-trust {
      font-size: 25px;
    }
    .institutional-imgs {
      max-height: 60px;
      margin-top: 20px;
    }
  }
  .leave-message-area {
    width: 100%;
    font-size: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-left: 0;
    padding: 20px 20px;
    margin-top: 20px;
  }
  .leave-message-title {
    font-size: 24px;
  }
  .leave-message-title-set {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #666666;
    line-height: 21px;
    font-style: normal;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .apply-now-title {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    color: #2d3f50;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 25px;
    color: #2d3f50;
    text-align: left;
    font-style: normal;
  }
}

.img-list {
  margin-left: 20px;
}

.leave-message-title {
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 25px;
  color: #2d3f50;
  line-height: 40px;
  text-align: left;
  font-style: normal;
}

::v-deep .ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}
.institutional-img-area {
  margin-top: 20px;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
}
.img-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
// 表單樣式
::v-deep .info .ant-input {
  background-color: #ffffff;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}
@media (max-width: 640px) {
  ::v-deep main .main .info[data-v-59611bc4] .ant-input {
    font-size: 20px;
  }
  ::v-deep .ant-form-item label {
    font-size: 20px;
  }
  ::v-deep main .main .info .input-content .input-content-input[data-v-6b074f01] {
    background-color: white;
  }
  ::v-deep main .main .info[data-v-6b074f01] .ant-input {
    background: white;
    height: 38px;
  }
  ::v-deep .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    height: 40px;
  }
  ::v-deep .ant-select-selection--single .ant-select-selection__rendered {
    top: 50%;
    transform: translateY(-50%);
  }
  ::v-deep .ant-select-selection--single {
    height: 40px;
    cursor: pointer;
  }
  ::v-deep main .main .info[data-v-6b074f01] .ant-input {
    font-size: 20px;
  }
}
</style>
