var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('navigation',{attrs:{"flat":true}}),_c('v-main',{staticClass:"pt-0"},[(_vm.isShowPhone)?_c('training-phone',{attrs:{"content":_vm.coverPhoneData}}):_vm._e(),(!_vm.isShowPhone)?_c('cover-view',{attrs:{"data":_vm.coverData}}):_vm._e(),_c('div',{staticClass:"video-exhibit"},[_c('div',{staticClass:"text-content"},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("field.leleTrainingTwo")))]),_c('p',[_vm._v(_vm._s(_vm.$t("content.teachingPlatform")))]),_c('p',{staticStyle:{"display":"flex","align-items":"center"}},[_vm._v(" "+_vm._s(_vm.$t("content.learnMore"))+" "),_c('v-img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require("@/assets/img/video-right.svg"),"contain":"","max-width":"25px"}})],1)])]),_c('div',{staticClass:"video-play"},[_c('video',{ref:"videoExhibitRef",attrs:{"src":"https://66.training/training_read_minio/other/171c1c8c-84d3-450a-87ff-a03ed8e91585.mp4","controls":""},on:{"play":function($event){return _vm.changeVideoPlayShow(false)},"pause":function($event){return _vm.changeVideoPlayShow(true)}}})])]),_c('desc-content-list',{attrs:{"content":_vm.descContent}}),_c('content-img',{attrs:{"image":require('@/assets/img/online-course-illustration.png'),"title":_vm.$t('online.onlineCourses'),"direction":"LTR","coverColor":'#f6faf7',"alignValue":'center',"features":[
          {
            content: _vm.$t('online.onlineAndOfflineDualLineCourses'),
            icon: require('@/assets/img/online-offline.svg'),
          },
          {
            content: _vm.$t('online.maximizingTeacherResources'),
            icon: require('@/assets/img/maximum-utilization.svg'),
          },
          {
            content: _vm.$t('online.teachingVideoRetention'),
            icon: require('@/assets/img/retention.svg'),
          },
        ]}}),_c('content-img',{attrs:{"image":require('@/assets/img/video-courses.png'),"title":_vm.$t('online.videoCourse'),"direction":"RTL","features":[
          {
            content: _vm.$t('online.videoCourseHelp'),
            icon: require('@/assets/img/videoCourseHelp.svg'),
          },
          {
            content: _vm.$t('online.packagingCombination'),
            icon: require('@/assets/img/packagingCombination.svg'),
          },
          {
            content: _vm.$t('online.supportOpenness'),
            icon: require('@/assets/img/supportOpenness.svg'),
          },
        ]}}),_c('content-img',{attrs:{"image":require('@/assets/img/train-student-management.png'),"title":_vm.$t('field.studentManagement'),"descs":[_vm.$t('content.studentsGenerateIndependentFiles')],"animation-entry":"animate__fadeIn","direction":"LTR","features":[],"coverColor":'#F3F9F5'}}),_c('content-img',{attrs:{"image":require('@/assets/img/train-communication.png'),"title":_vm.$t('field.communication'),"descs":[_vm.$t('content.efficientCommunication')],"direction":"RTL","features":[
          {
            title: _vm.$t('field.instantMessaging'),
            content: _vm.$t('content.privateCommunicationTools'),
            icon: require('@/assets/img/train-communication-chat.svg'),
          },
          {
            title: _vm.$t('field.informationRelease'),
            content: _vm.$t('content.inChatGroups'),
            icon: require('@/assets/img/train-communication-notice.svg'),
          },
          {
            title: _vm.$t('field.studentsTakingLeave'),
            content: _vm.$t('content.leaveInformationIsNotSynchronized'),
            icon: require('@/assets/img/train-communication-leave.svg'),
          },
        ]}}),_c('content-img',{attrs:{"image":require('@/assets/img/train-paySetting.png'),"title":_vm.$t('field.teacherFeeSetting'),"descs":[_vm.$t('content.salarySituation')],"direction":"LTR","coverColor":'#F3F9F5',"features":[
          {
            content: _vm.$t('content.setType'),
            icon: require('@/assets/img/train-paySetting-money.svg'),
          },
          {
            content: _vm.$t('content.billingMethod'),
            icon: require('@/assets/img/train-paySetting-person.svg'),
          },
          {
            content: _vm.$t('content.attendanceRecord'),
            icon: require('@/assets/img/train-paySetting-calendar.svg'),
          },
        ]}}),_c('content-img',{attrs:{"image":require('@/assets/img/train-finance.png'),"title":_vm.$t('field.financialFunctions'),"direction":"RTL","features":[
          {
            content: _vm.$t('content.costSituation'),
            icon: require('@/assets/img/train-finance-icon1.svg'),
          },
          {
            content: _vm.$t('content.throughDataStatisticsAndAnalysis'),
            icon: require('@/assets/img/train-finance-icon2.svg'),
          },
        ]}}),_c('content-img',{staticStyle:{"padding":"40px 0"},attrs:{"image":require('@/assets/img/video-courses.png'),"title":_vm.$t('online.privateCloudServicePlan'),"direction":"LTR","coverColor":'#F3F9F5',"features":[
          {
            content: _vm.$t('online.helpBuild'),
            icon: require('@/assets/img/helpBuild.svg'),
          },
          {
            content: _vm.$t('online.enableInstitutionsTo'),
            icon: require('@/assets/img/enableInstitutionsTo.svg'),
          },
          {
            content: _vm.$t('online.supportOpenness'),
            icon: require('@/assets/img/macauTelecomCloudServices.svg'),
          },
        ]}}),_c('content-img',{attrs:{"image":require('@/assets/img/train-admin.png'),"title":_vm.$t('field.administratorApp'),"descs":[_vm.$t('content.importantInformation')],"direction":"RTL","coverColor":'#ffffff',"features":[
          {
            content: _vm.$t('content.controllableAndTraceable'),
            icon: require('@/assets/img/train-admin-icon1.svg'),
          },
          {
            content: _vm.$t('content.provideDataSupport'),
            icon: require('@/assets/img/train-admin-icon2.svg'),
          },
          {
            content: _vm.$t('content.improveTheQualityOfInstitutionalServices'),
            icon: require('@/assets/img/train-admin-icon3.svg'),
          },
        ]}}),_c('desc-content-list',{attrs:{"content":_vm.vxContent}}),_c('customization-area')],1),_c('v-scale-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.fab),expression:"fab"},{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],attrs:{"fab":"","dark":"","fixed":"","bottom":"","right":"","color":"secondary"},on:{"click":_vm.toTop}},[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)],1),_c('foote')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }