<template>
  <v-date-picker v-model="_value" color="#FE880A" @change="change" no-title :width="width" :allowedDates="allowedDatesFunc" class="reserve-calendar"> </v-date-picker>
</template>

<script setup>
import dayjs from "dayjs";
import { computed } from "vue";

const emits = defineEmits(["update:value", "change"]);
const props = defineProps({
  value: {
    type: String,
  },
  allowedDates: {
    type: Array,
    default: () => [],
  },
  width: {
    type: [String, Number],
    default: 420,
  },
});

const _value = computed({
  get() {
    return props.value;
  },
  set(value) {
    emits("update:value", value);
  },
});
function change() {
  emits("change");
}

const allowedDatesFunc = (value) => {
  return props.allowedDates.map((dateStr) => dayjs(dateStr)).find((date) => date.isSame(dayjs(value)));
};
</script>

<style scoped lang="scss">
.reserve-calendar {
  ::v-deep .v-btn {
    background: #fff0df;
    color: #fe880a;

    &--active {
      border: none !important;
      color: #ffffff;
    }
  }

  ::v-deep .v-btn--disabled {
    background: transparent !important;
  }

  ::v-deep .v-date-picker-header {
    margin-bottom: 49px;
    padding: 0 15%;
  }
}

::v-deep .v-date-picker-table {
  height: auto;
  td {
    padding: 5px;
  }
}
</style>
