<template>
  <v-main class="pt-0 mt-8">
    <main>
      <div class="main">
        <div style="padding: 20px; background: #ffffff; box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.16); border-radius: 10px">
          <!-- <div
            class=""
            style="cursor: pointer; display: flex; align-items: center"
            @click="step == 1 ? $emit('cancelAppointment') : (step = 1)"
          >
            <span>
              <v-img src="@/assets/img/left-icon.png" max-width="13px" />
            </span>
            <span style="margin-left: 5px">
              {{ $t("content.return") }}
            </span>
          </div> -->
          <div class="big-area">
            <!-- left 卡片 -->
            <div>
              <!-- logo -->
              <div class="flex" style="justify-content: center; text-align: center">
                <div>
                  <div class="flex" style="justify-content: center; text-align: center">
                    <div>
                      <v-img src="@/assets/img/orange-logo.svg" max-width="50px" />
                    </div>
                    <div class="flex" style="margin-left: 20px; font-size: 40px">
                      {{ $t("field.lelePlatform") }}
                    </div>
                  </div>
                </div>
              </div>
              <!-- 安排 -->
              <div class="schedule">
                {{ $t("content.schedulePresentationAppointment") }}
              </div>
              <div class="the-content">
                <div class="flex-package">
                  <div>
                    <v-img src="@/assets/img/online-demonstration.svg" max-width="35px" />
                  </div>
                  <div class="flex" style="margin-left: 20px; font-size: 15px">
                    {{ $t("field.onlineDemonstration") }}
                  </div>
                </div>
                <div class="flex-package" style="display: flex; align-items: center">
                  <div>
                    <v-img src="@/assets/img/teams.svg" max-width="35px" />
                  </div>
                  <div class="flex" style="margin-left: 20px; font-size: 15px">
                    Teams/Voov Meeting {{ $t("field.tencentMeeting") }}
                    <!--  / Microsoft Team -->
                  </div>
                </div>
                <div class="flex-package">
                  <div>
                    <v-img src="@/assets/img/one-time.svg" max-width="35px" />
                  </div>
                  <div class="flex" style="margin-left: 20px; font-size: 15px">
                    {{ "1" + $t("field.hour") }}
                  </div>
                </div>
                <div class="flex-package" style="margin-bottom: 20px">
                  <div>
                    <v-img src="@/assets/img/introduce.svg" max-width="35px" />
                  </div>
                  <div class="flex" style="margin-left: 20px; font-size: 15px">
                    <div>
                      <div>10 {{ $t("field.minute") }} {{ $t("field.productIntroduction") }}</div>
                      <div>40 {{ $t("field.minute") }} {{ $t("field.systemDemonstration") }}</div>
                      <div>10 {{ $t("field.minute") }} {{ $t("field.answerQuestion") }}</div>
                    </div>
                  </div>
                </div>
                <div style="margin-top: 20px" v-if="step == 2">
                  <span class="book-time-title">
                    {{ $t("field.theTimeYouBooked") }}
                  </span>
                  <div style="margin-top: 20px">
                    <div class="time-text">
                      <v-icon large color="#666666">mdi-clock-time-nine-outline</v-icon>
                      <span>{{ selectTime }} {{ selectTimePoint }}-{{ dayjs(`2000-01-01 ${selectTimePoint}`).add(1, "hour").format("HH:mm") }}</span>
                    </div>
                    <div @click="onSubmit()" html-type="submit" style="">
                      <a-button
                        @click="step = 1"
                        style="
                          width: 200px;
                          height: 40px;
                          background: #ffffff;
                          box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
                          border-radius: 33px;
                          font-size: 20px;
                          color: #ffffff;
                          border: 1px solid #fe880a;
                          color: #fe880a;
                        "
                      >
                        {{ $t("field.changeTime") }}
                      </a-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="step == 1">
              <div class="select-time">
                {{ $t("content.selectDate") }}
              </div>
              <reserve-calendar :value.sync="selectTime" :width="'100%'" @change="onChangeTime(selectTime)" :allowed-dates="allowAppointments" />
              <div class="select-date-area" v-if="selectTime">
                <div class="select-date-area-title">{{ selectTime }} {{ getDayOfWeek(selectTime) }}</div>
                <div style="margin-top: 20px; display: flex; flex-wrap: wrap">
                  <a-radio-group v-model="selectTimePoint" @change="onChange()" style="display: flex">
                    <div>
                      <a-radio-button
                        value="10:00"
                        style="border-radius: 15px; flex: 1"
                        :disabled="ifDisabled(selectTime, '10:00')"
                        :class="(ifDisabled(selectTime, '10:00') ? '' : 'select-no', selectTimePoint == '10:00' ? 'select-yes' : 'select-date-area-btn')"
                      >
                        10:00
                        <div class="already-expired" v-if="ifDisabled(selectTime, '10:00')">
                          (
                          {{ $t("field.alreadyExpired") }}
                          )
                        </div>
                      </a-radio-button>
                    </div>
                    <div>
                      <a-radio-button
                        value="15:00"
                        style="border-radius: 15px; flex: 1"
                        :disabled="ifDisabled(selectTime, '15:00')"
                        :class="(ifDisabled(selectTime, '10:00') ? '' : 'select-no', selectTimePoint == '15:00' ? 'select-yes' : 'select-date-area-btn')"
                      >
                        15:00

                        <div class="already-expired" v-if="ifDisabled(selectTime, '15:00')">
                          (
                          {{ $t("field.alreadyExpired") }}
                          )
                        </div>
                      </a-radio-button>
                    </div>
                  </a-radio-group>
                </div>
                <div>
                  <a-button class="round-button" @click.stop="nextStep()">
                    {{ $t("field.nextStep") }}
                  </a-button>
                </div>
              </div>
            </div>
            <!-- rigth 卡片 -->
            <div v-if="step == 2">
              <div class="leave-message-area">
                <div class="leave-message-title">
                  {{ $t("content.leaveMessage") }}
                </div>
                <div class="leave-message-title-set">
                  {{ $t("content.leaveYourContactInformation") }}
                </div>

                <!-- 表單 -->
                <div style="margin-top: 20px">
                  <a-form-model layout="vertical" ref="ruleForm" :model="formData" :rules="rules" @submit="onSubmit()">
                    <div class="basic">
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item name="username" prop="userName" :label="$t('field.name').charAt(0).toUpperCase() + $t('field.name').slice(1)">
                            <a-input class="input-content-input" v-model="formData.userName" :placeholder="$t('field.name')" />
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item
                            name="gender"
                            :label="$t('content.whatDoWeCallYou')"
                          >
                            <a-select
                              class="input-content-input"
                              v-model="formData.gender"
                              style="width: 100%"
                              :placeholder="$t('content.whatDoWeCallYou')"
                            >
                              <a-select-option :value="1">
                                {{ $t("field.sir") }}
                              </a-select-option>
                              <a-select-option :value="2">
                                {{ $t("field.miss") }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </div>
                      </div> -->
                      <!-- 邮箱地址 -->
                      <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item :label="$t('field.email')">
                            <a-input class="input-content-input" v-model="formData.mail" :placeholder="$t('field.email')" />
                          </a-form-model-item>
                        </div>
                      </div> -->
                      <!-- 地區 -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item prop="areaCode" :label="$t('field.region').charAt(0).toUpperCase() + $t('field.region').slice(1)">
                            <a-select class="input-content-input" v-model="formData.areaCode" style="width: 100%" :placeholder="$t('field.region')">
                              <a-select-option v-for="(item, i) of phoneNumberList" :key="item.country_id" :value="'+' + item.country_code">
                                {{ item.country_name_en }}
                                + {{ item.country_code }}
                              </a-select-option>
                            </a-select>
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- telphone -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item prop="tel" :label="$t('field.contactNumber')">
                            <a-input class="input-content-input" :placeholder="$t('field.contactNumber')" default-value="mysite" v-model="formData.tel" :max-length="9" type="number"> </a-input>
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- code -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item prop="code" :label="$t('field.code')">
                            <a-input v-model="formData.code" :placeholder="$t('field.code')" class="input-content-input" style="height: 40px; font-size: 20px !important">
                              <div slot="addonAfter" @click="!loading ? sendCode() : ''">
                                <span class="send-text">
                                  {{ !loading ? $t("field.sendCode") : `${surplusSecond}s` }}
                                </span>
                              </div>
                            </a-input>
                          </a-form-model-item>
                        </div>
                      </div>

                      <!-- companyName -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item prop="companyName" :label="$t('field.yourOrganizationName')">
                            <a-input class="input-content-input" v-model="formData.companyName" :placeholder="$t('field.yourOrganizationName')" />
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- companyAddress -->
                      <!-- <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item :label="$t('field.companyAddress')">
                            <a-input class="input-content-input" v-model="formData.companyAddress" :placeholder="$t('field.companyAddress')" />
                          </a-form-model-item>
                        </div>
                      </div> -->
                      <!-- youIdentityIs -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item prop="youIdentityIs" :label="$t('field.youIdentityIs')">
                            <a-radio-group class=" " style="display: flex; flex-wrap: wrap" v-model="formData.youIdentityIs" @change="onChange">
                              <a-radio :value="$t('field.institutionalLegalRepresentative')">
                                {{ $t("field.institutionalLegalRepresentative") }}
                              </a-radio>
                              <a-radio :value="$t('field.administrativeStaff')">
                                {{ $t("field.administrativeStaff") }}
                              </a-radio>
                              <a-radio :value="$t('field.staff')">
                                {{ $t("field.staff") }}
                              </a-radio>
                              <a-radio :value="$t('field.other')">
                                {{ $t("field.other") }}
                              </a-radio>
                            </a-radio-group>
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- interested -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item :label="$t('field.interested')" required prop="trainingType">
                            <a-checkbox-group v-model:value="formData.trainingType" class="w-full" style="width: 100%">
                              <div class="checkbox-training-type-options">
                                <a-checkbox v-for="(item, index) of trainingTypeOptions" :key="index" :value="item.label">
                                  {{ item.label }}
                                </a-checkbox>
                              </div>
                            </a-checkbox-group>
                          </a-form-model-item>
                        </div>
                      </div>
                      <!-- invitationCode -->
                      <div class="user info">
                        <div class="input-content flex" style="display: flex">
                          <a-form-model-item name="invitationCode" prop="invitationCode" style="" :label="$t('content.invitationCode').charAt(0).toUpperCase() + $t('content.invitationCode').slice(1)">
                            <a-input class="input-content-input" v-model="formData.invitationCode" :placeholder="$t('content.invitationCode')" />
                          </a-form-model-item>
                        </div>
                      </div>
                      <div class="contact-details info">
                        <div class="input-content flex" style="display: flex; padding-bottom: 0 !important"></div>
                      </div>
                    </div>

                    <div class="input-content flex confirmContent" style="display: flex">
                      <a-form-model-item required prop="confirmContent">
                        <a-checkbox-group class="input-content-input" v-model:value="formData.confirmContent" style="width: 100%">
                          <div class="checkbox-training-type-options">
                            <a-checkbox v-for="(item, index) of confirmContentOptions" :key="index" :value="item.label">
                              {{ item.label }}
                            </a-checkbox>
                          </div>
                        </a-checkbox-group>
                      </a-form-model-item>
                    </div>
                    <div>
                      <a-form-item>
                        <div class="flex justify-center submit-button" @click="onSubmit()" html-type="submit" style="display: flex; justify-content: center">
                          <a-button> {{ $t("field.submit") }}</a-button>
                        </div>
                      </a-form-item>
                    </div>
                  </a-form-model>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppointmentSuccessful :openDialog="openDialog" @close="close" v-if="openDialog" />
    </main>
  </v-main>
</template>
<script>
import navigation from "@/components/common/NavigationBar";
import contentImg from "@/components/common/contentImg";
import axios from "axios";
import { useCountdown } from "@/hooks/web/useCountdown";
import { useRoute } from "vue-router";
import phoneNumberList from "@/hooks/phoneNumber";
import ReserveCalendar from "@/components/ReserveCalendar/ReserveCalendar";
import AppointmentSuccessful from "@/views/leave_message/components/AppointmentSuccessful";
import { objectToFormat } from "@/utils/dateUtilFun";
import dayjs, { Dayjs } from "dayjs";

const { surplusSecond, start: startCountdown, loading } = useCountdown(60);

export function isMacaoTel(phone) {
  return /^(\+853\s)?(6\d{3}(\s)?\d{4})$/.test(phone);
}
export function isHongKongTel(phone) {
  return /^(\+852\s)?[569]\d{3}\s?\d{4}$/.test(phone);
}
export function isChinaTel(phone) {
  return /^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(phone);
}

export default {
  name: "App",
  components: {
    navigation,
    contentImg,
    surplusSecond,
    loading,
    useRoute,
    ReserveCalendar,
    objectToFormat,
    AppointmentSuccessful,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
    surplusSecond,
    phoneNumberList,
    timeList: [],
    step: 1,
    selectTime: null,
    selectTimePoint: null,
    allowAppointments: [],
    loading,
    codeType: "",
    formData: {
      userName: "",
      gender: undefined,
      areaCode: undefined,
      tel: "",
      region: "",
      mail: "",
      consultingService: "",
      message: "",
      companyName: "",
      companyAddress: "",
      trainingMethod: [],
      trainingType: [],
      studentNumber: "",
      nature: "",
      youIdentityIs: undefined,
      contactYouWay: [],
      info: false,
      confirmContent: [],
      invitationCode: "",
    },
    openDialog: false,
  }),
  computed: {
    dayjs() {
      return dayjs;
    },

    rules() {
      let _this = this;
      let rules = {
        userName: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.name"),
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.contactNumber"),
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              let isPass = false;
              switch (this.formData.areaCode) {
                case "+853":
                  isPass = isMacaoTel(this.formData.tel);
                  break;
                case "+852":
                  isPass = isHongKongTel(this.formData.tel);
                  break;
                case "+86":
                  isPass = isChinaTel(this.formData.tel);
                  break;
                case "":
                  isPass = false;
                  break;
                default:
                  isPass = true;
                  break;
              }
              if (isPass || value == "") {
                callback();
              } else {
                // callback(new Error(_this.$t("field.pleaseEnter") + _this.$t("field.contactNumber")));
                callback(new Error(_this.$t("content.pleaseConfirmPhoneNumberCorrect")));
              }
              return isPass;
            },
          },
        ],
        areaCode: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.region"),
            trigger: "change",
          },
        ],
        companyName: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.yourOrganizationName"),
            trigger: "blur",
          },
        ],
        trainingType: [
          {
            required: true,
            message: _this.$t("field.selectAtLeastOne"),
            trigger: "change",
          },
        ],
        confirmContent: [
          {
            required: true,
            message: _this.$t("content.pleaseCheck"),
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.code"),
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              // 假设你有一个名为 checkCode 的方法来检查验证码是否失败
              // 这里假设 checkCode 方法返回一个 Promise
              _this
                .checkCode()
                .then((res) => {
                  if (res.data.code != 200) {
                    // 验证码失败时，调用 callback 并传入错误消息
                    callback(new Error("验证码错误"));
                  } else {
                    // 验证码通过时，调用 callback 但不传入参数
                    callback();
                  }
                })
                .catch((error) => {
                  // 处理校验失败的情况
                  callback(new Error("校验失败：" + error.message));
                });
            },
            trigger: "submit",
          },
        ],
        contactYouWay: [
          {
            required: true,
            message: _this.$t("field.selectAtLeastOne"),
            trigger: "change",
          },
        ],
        youIdentityIs: [
          {
            required: true,
            message: _this.$t("field.pleaseEnter") + _this.$t("field.messageyYouIdentityIs"),
            trigger: "change",
          },
        ],
      };
      return rules;
    },
    consultingServiceOptions() {
      let _this = this;
      let consultingServiceOptions = [
        { label: _this.$t("content.productDoorConsultationService"), value: 0 },
        { label: _this.$t("content.callForProductDetails"), value: 1 },
        { label: _this.$t("content.wantInformationQuestions"), value: 2 },
        {
          label: _this.$t("content.onSiteProductTrainingIsRequired"),
          value: 3,
        },
      ];
      return consultingServiceOptions;
    },
    studentNumberOptions() {
      let _this = this;
      let studentNumberOptions = [
        { label: "20~100", value: 0 },
        { label: "100~500", value: 1 },
        { label: "500~1000", value: 2 },
        { label: _this.$t("content.moreThan1000"), value: 3 },
      ];
      return studentNumberOptions;
    },
    trainingTypeOptions() {
      let _this = this;
      let trainingTypeOptions = [
        { label: _this.$t("field.leleTrainingParenthesis"), value: 0 },
        { label: _this.$t("field.leleMallParenthesis"), value: 1 },
        { label: _this.$t("field.leleMarketingService"), value: 2 },
        { label: _this.$t("field.leleAgent"), value: 3 },
      ];
      return trainingTypeOptions;
    },
    confirmContentOptions() {
      let _this = this;
      let trainingTypeOptions = [
        {
          label: _this.$t("content.whatIHaveFilledInIsTrueAndValid"),
          value: 0,
        },
      ];
      return trainingTypeOptions;
    },
    contactYouWayOptions() {
      let _this = this;
      let contactYouWayOptions = [
        { label: _this.$t("field.callMe"), value: 0 },
        { label: _this.$t("field.whatsapp"), value: 1 },
        { label: _this.$t("field.SMS"), value: 2 },
      ];
      return contactYouWayOptions;
    },

    characterOptions() {
      let _this = this;
      let characterOptions = [
        { label: _this.$t("field.personalTrainers"), value: 0 },
        { label: _this.$t("field.studioTrainingRoom"), value: 1 },
        { label: _this.$t("field.clubs"), value: 2 },
        {
          label: _this.$t("field.trainingInstitutionsWithLessThan10Teachers"),
          value: 3,
        },
        {
          label: _this.$t("field.largeInstitutionsWithMoreThan10FacultyMembers"),
          value: 4,
        },
        { label: _this.$t("field.chainInstitutions"), value: 5 },
      ];
      return characterOptions;
    },
    getLastEffectiveTime() {
      const date = dayjs();
      // 默認選擇當前那一天
      if (![0, 6].includes(date.day())) {
        return date.format("YYYY-MM-DD");
      } else {
        return date.add(1, "week").startOf("week").add(1, "day").format("YYYY-MM-DD");
      }
    },
  },
  created() {
    const top = window.pageYOffset || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
    this.info = true;

    // 要解密的Base64编码的字符串
    var encodedString = "bnZpdGF0aW9uQ29kZT1TYWxlczAx";

    // 使用atob()函数解密Base64编码的字符串
    var decodedString = atob(encodedString);

    console.log(decodedString); // 输出解密后的字符串
    const useRoute = this.$route;
    console.log(111, useRoute.query.invitationCode, this.$route);
    if (useRoute.query.invitationCode) {
      this.formData.invitationCode = atob(useRoute.query.invitationCode);
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
  methods: {
    getDayOfWeek(dateString) {
      if (!dateString) {
        return;
      }
      let _this = this;
      const date = new Date(dateString);
      const days = [
        _this.$t("content.sunday"),
        _this.$t("content.monday"),
        _this.$t("content.tuesday"),
        _this.$t("content.wednesday"),
        _this.$t("content.thursday"),
        _this.$t("content.friday"),
        _this.$t("content.saturday"),
      ];
      const dayOfWeek = date.getDay();
      return days[dayOfWeek];
    },
    nextStep() {
      if (this.selectTimePoint == null || this.selectTimePoint == "Invalid Date") {
        return;
      }
      this.step = 2;
    },
    ifDisabled(date, time) {
      let a = false;
      if (this.timeList.length > 0) {
        this.timeList.forEach((element) => {
          if (element.date == date) {
            if (element.time == time) {
              a = true;
              return a;
            }
          }
        });
        console.log(5555, this.timeList);
        return a;
      } else {
        console.log(666666, this.timeList);
        return a;
      }
    },

    getTelphone() {
      return this.formData.areaCode + " " + this.formData.tel;
    },
    sendCode() {
      let hasError = false;
      // 注意事项：校验多项时，第一个参数传数组，回调函数会触发多次
      this.$refs.ruleForm.validateField(["tel", "areaCode"], (valid) => {
        // 有值就会判断
        if (valid) {
          hasError = true;
          return;
        }
      });
      if (!hasError) {
        // 不存在校验不通过的项，请求探测接口...
        console.log("不存在校验不通过的项，请求探测接口");

        // 后面是接口
        let phone = encodeURIComponent(this.getTelphone());
        axios({
          method: "get",
          url: `https://66.training/training_api/base/base/verification-code/getCode/${phone}?client=1`,
          // url: `http://192.168.0.254:9529/training_api/base/base/verification-code/getCode/${phone}?client=1`,
        }).then(function (response) {
          if (response.data.code == 200) {
            startCountdown();
            _this.codeType = response.data.data.type;
            console.log(22323, _this.codeType, response);
          }
        });
      }
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 60;
    },

    close() {
      this.openDialog = false;
      location.reload();
    },
    checkCode() {
      let _this = this;
      let phone = encodeURIComponent(this.getTelphone());
      return axios({
        method: "get",
        url: `https://66.training/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
        // url: `http://192.168.0.254:9529/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
      });
    },

    onSubmit(e) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let _this = this;
          let phone = encodeURIComponent(this.getTelphone());
          axios({
            method: "get",
            url: `https://66.training/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
            // url: `http://192.168.0.254:9529/training_api/base/base/verification-code/verify/${this.formData.code}?client=1&codeType=${this.codeType}&telPhone=${phone}`,
          }).then(function (response) {
            console.log(1111, response);
            const data = {
              companyAddress: _this.formData.companyAddress,
              companyName: _this.formData.companyName,
              email: _this.formData.mail,
              gender: _this.formData.gender,
              // telphone: `${formData.value.telAreaCode} ${formData.value.tel}`,
              telphone: _this.getTelphone(),
              username: _this.formData.userName,
              // functionOption: ["答复"]
              date: _this.selectTime || "",
              time: _this.selectTimePoint || "",
              functionOption: _this.formData.trainingType,
              invitationCode: _this.formData.invitationCode,
              role: _this.formData.youIdentityIs,
              contactYouWay: _this.formData.contactYouWay,
            };
            axios({
              method: "post",
              url: "https://66.training/training_api/base/base/send/mail/sendMailToUs",
              // url: `http://192.168.0.254:9529/training_api/base/base/send/mail/sendMailToUs`,
              data: data,
            }).then(function (response) {
              if (response.data.code == 200) {
                // this.openDialog = true;
                _this.openDialog = true;
              } else {
                _this.$message.error(response.data.message);
              }
            });
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    getTimeList(e) {
      let req = {
        date: objectToFormat(dayjs(), "YYYY-MM"),
      };
      console.log("getTimeList", e);
      if (e) {
        req.date = e;
      }
      axios({
        method: "post",
        url: `https://66.training/training_api/base/base/company-user/getCompanyUserList`,
        // url: `http://192.168.0.254:9529/training_api/base/base/company-user/getCompanyUserList`,
        data: req,
      }).then((response) => {
        this.allowAppointments = [];
        for (let i = 0; i < 31; i++) {
          const dayDate = dayjs().add(i, "day");
          if (![0, 6].includes(dayDate.day())) {
            this.allowAppointments.push(dayDate.format("YYYY-MM-DD"));
          }
        }
        this.timeList = response.data.data;
        // 定义时间点数组
        let times = ["10:00", "15:00"];

        // 获取今天、明天和后天的日期
        let days = [dayjs().format("YYYY-MM-DD"), dayjs().add(1, "day").format("YYYY-MM-DD"), dayjs().add(2, "day").format("YYYY-MM-DD")];

        // 遍历时间点和日期，创建对象并添加到数组
        days.forEach((day) => {
          times.forEach((time) => {
            this.timeList.push({ date: day, time: time });
          });
        });
      });
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    onChangeTime(e) {
      console.log(222, dayjs(e).format("YYYY-MM"));
      this.getTimeList(dayjs(e).format("YYYY-MM"));
      console.log(3333, dayjs(e).format("YYYY-MM"));

      this.selectTimePoint = null;
    },
    onChange(e) {
      console.log(111123213213, e);
    },
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },
  mounted() {
    console.log(12313, "onm");
    this.getTimeList();
    this.selectTime = this.getLastEffectiveTime;
  },
};
</script>
<style>
@primary-color: #f5222d;
/* * {
  font-family: MicrosoftYaHei, MicrosoftYaHei !important;
} */
.v-application ul {
  padding-left: 0 !important;
}
</style>
<style lang="scss" scoped>
::v-deep .ant-input-group-addon {
  padding: 0 !important;
}

::v-deep .has-error .ant-input-group-addon {
  border-color: #ffffff !important;
}

::v-deep .ant-input-group-addon {
  border-color: #ffffff !important;
}
.flex-package {
  display: flex;
  align-items: baseline;
  justify-content: center;
  // align-items: center;
  margin-top: 20px;
}
.submit-button {
  .ant-btn {
    width: 200px;
    height: 40px;
    background: #fe880a;
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    border-radius: 33px;
    font-size: 20px;
    // font-family: MicrosoftYaHei;
    color: #ffffff;
    border: none;

    &:after {
      box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    }
  }

  @media (max-width: 640px) {
    .ant-btn {
      width: 180px;
      height: 40px;
      background: linear-gradient(139deg, #fbdc79 0%, #ff5965 100%);
      box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
      border-radius: 33px;
      font-size: 16px;
      // font-family: MicrosoftYaHei;
      color: #ffffff;
      border: none;

      &:after {
        box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
      }
    }
  }
}

.v-application .info {
  background-color: #fff !important;
}

.header {
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .welcome {
    font-size: 15px;
    margin-top: 5px;
  }
}
.flex {
  display: flex;
}
@media (max-width: 640px) {
  main {
    // padding: 50px 300px;
    .main {
      padding: 50px 10px 20px;
      .schedule {
        font-weight: 600;
        font-size: 25px;
        color: #333333;
        text-align: left;
        font-style: normal;
        margin-top: 20px;
      }
      .select-time {
        display: flex;
        justify-content: center;
        font-size: 25px;
        font-weight: 600;
        padding: 20px;
      }
      .info {
        .title {
          display: flex;
          align-items: baseline;
          font-size: 17px !important;
          word-break: break-all;
          font-weight: bold;
          color: #333333;

          &:before {
            margin-right: 6px;
            display: block;
            content: " ";
            width: 4px;
            height: 16px;
            background: linear-gradient(65deg, #f8d97f 0%, #ff8c65 100%);
            border-radius: 2px;
          }
        }

        .input-content {
          flex-direction: column;
          min-height: 50px;
          .input-content-input {
            height: 100%;
            min-height: 40px;
            line-height: 40px;
            font-size: 20px;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
          }

          .ant-form-item {
            &:last-child {
              margin-right: 0;
            }

            &-label {
              > label {
                font-size: 16px;
                // font-family: MicrosoftYaHei;
                color: #666666;
                line-height: 21px;
              }
            }
          }

          .hidden.placeholder {
            display: none;
          }
        }

        .open-test-account {
          margin-top: 20px;

          .label {
            font-size: 16px;
            color: #666666;
          }
        }

        ::v-deep .ant-input {
          background: #f2f2f2;
          border-radius: 4px;
          border: none;

          &:focus {
            box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
          }
        }

        ::v-deep textarea {
          &.ant-input {
            height: auto;
          }
        }

        ::v-deep .ant-select {
          &-selector {
            background: #f2f2f2;
            border-radius: 4px;
            border: none;
            //height: 56px;
          }

          &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            box-shadow: 0 0 0 2px rgb(255 118 81 / 20%);
            border-color: red;
          }
        }

        .anticon-caret-down {
          font-size: 16px;
          color: #333333;
        }

        ::v-deep .ant-radio {
          &-group {
            display: flex;
            justify-content: start;
            padding-right: 30px;
          }
        }

        ::v-deep .ant-checkbox {
          &-group {
            display: flex;
            justify-content: start;
            width: 100%;
            padding-right: 30px;
          }
        }

        ::v-deep .ant-checkbox-group {
          width: 100%;
          padding-right: 0;
        }

        .checkbox-training-type-options {
          display: grid;
          width: 100%;
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 16px 0;
        }

        .confirmContent {
          .checkbox-training-type-options {
            display: grid;
            width: 100%;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 16px 0;
          }
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .select-time {
    font-size: 30px;
    font-weight: 600;
    padding: 20px;
  }
  main {
    .main {
      padding: 65px 30px;
    }
    .big-area {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px;
    }
    .schedule {
      font-weight: 600;
      font-size: 35px;
      color: #333333;
      line-height: 56px;
      text-align: left;
      font-style: normal;
      margin-top: 80px;
    }
  }
}

@media (min-width: 1024px) {
  main {
    padding: 0px 250px;
  }
}

.send-text {
  color: #f58848;
  font-size: 20px;
  cursor: pointer;
}

// new 01

.over-companies {
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 36px;
  color: #2d3f50;
  line-height: 47px;
  text-align: left;
  font-style: normal;
}

.institutional-trust {
  font-family: MicrosoftYaHei;
  font-size: 36px;
  color: #2d3f50;
  line-height: 47px;
  text-align: left;
  font-style: normal;
}
@media (min-width: 960px) {
  .class-out-title {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 40px;
    color: #2d3f50;
    line-height: 66px;
    text-align: left;
    font-style: normal;
  }
  .institutional-trust-area {
    width: 600px;
    height: 246px;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-top: 200px;
    .institutional-imgs {
      max-height: 80px;
    }
  }
  .leave-message-area {
    width: 600px;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-left: 50px;
    padding: 20px 20px;
  }
  .leave-message-title-set {
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #666666;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    margin-top: 10px;
  }
  .apply-now-title {
    font-family: MicrosoftYaHei;
    font-size: 50px;
    color: #2d3f50;
    line-height: 79px;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 36px;
    color: #2d3f50;
    line-height: 47px;
    text-align: left;
    font-style: normal;
  }
}
@media (max-width: 959.999px) {
  .class-out-title {
    font-size: 35px;
    color: #2d3f50;
    line-height: 66px;
    text-align: left;
    font-style: normal;
    margin-top: 50px;
  }
  .institutional-trust-area {
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 20px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-top: 20px;
    .over-companies,
    .institutional-trust {
      font-size: 25px;
    }
    .institutional-imgs {
      max-height: 60px;
      margin-top: 20px;
    }
  }
  .leave-message-area {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 30px 0px rgba(45, 63, 80, 0.21);
    border-radius: 14px;
    margin-left: 0;
    padding: 20px 5px;
    margin-top: 20px;
  }
  .leave-message-title-set {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #666666;
    line-height: 21px;
    font-style: normal;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  .apply-now-title {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    color: #2d3f50;
    line-height: 79px;
    text-align: left;
    font-style: normal;
  }
  .belong-title {
    font-size: 25px;
    color: #2d3f50;
    line-height: 47px;
    text-align: left;
    font-style: normal;
  }
}

.img-list {
  margin-left: 20px;
}

.leave-message-title {
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: bold;
  font-size: 25px;
  color: #2d3f50;
  line-height: 40px;
  text-align: left;
  font-style: normal;
}

::v-deep .ant-form-vertical .ant-form-item {
  margin-bottom: 0;
}
.institutional-img-area {
  margin-top: 20px;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
}
.img-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.book-time-title {
  font-family: PingFang-SC, PingFang-SC;
  font-weight: 800;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: left;
  font-style: normal;
  position: relative;
  padding-left: 10px;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    height: 80%;
    background: #fe880a;
    // 全部div的高度
    top: 50%;
    left: 0;
    text-align: center;
    // 自己的高度
    transform: translateY(-50%);
  }
}
.select-no {
  background-color: #fe880a;
  color: white;
  border-radius: 10px;
  padding: 40px 10px;
}
::v-deep .ant-radio-button-wrapper {
  color: white;
}
.select-yes {
  min-width: 105px;
  min-height: 85px;
  color: #fe880a;
  background: #fff;
  border-color: #fe880a;
  padding: 40px 10px;

  font-size: 20px;
  flex: 1;
  margin-right: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
::v-deep .ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fe880a;
  background: #fff;
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper:hover {
  color: #fe880a;
  background: #fff;
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  color: #fe880a;
  background: #fff;
  background-color: #fe880a;
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fe880a;
  background: #fff;
  background-color: #fff !important;
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  color: #fe880a;
  background: #fff;
  background-color: #fe880a;
  border-color: #fe880a;
}
::v-deep .ant-radio-button-wrapper:not(:first-child)::before {
  color: #fe880a;
  background: #fff;
  background-color: #ffebe6;
  border-color: #fe880a;
}
.select-date-area {
  padding: 20px;
  &-title {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 33px;
    text-align: left;
    font-style: normal;
  }
  &-btn {
    min-width: 105px;
    min-height: 85px;
    padding: 40px 10px;
    background: #fe880a;
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    font-size: 20px;
    flex: 1;

    margin-right: 20px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .already-expired-btn {
    width: 200px;
    height: 70px;
    background: #ebebeb;
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    font-size: 20px;
    color: #999999;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .already-expired {
    font-size: 15px;
  }
  .round-button {
    width: 200px;
    height: 40px;
    background: #fe880a;
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    border-radius: 33px;
    font-size: 20px;
    color: #ffffff;
    margin-top: 20px;
    // 別做多餘操作
    // &:hover {
    //   width: 200px;
    //   height: 40px;
    //   background: #ffffff;
    //   box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
    //   border-radius: 33px;
    //   font-size: 20px;
    //   color: #ffffff;
    //   border: 1px solid #fe880a;
    //   color: #fe880a;
    //   margin-top: 20px;
    // }
  }
}

.time-text {
  font-weight: 800;
  font-size: 20px;
  color: #666666;
  margin-bottom: 10px;
}
.input-content {
  ::v-deep .ant-form-item {
    width: 100%;
  }
}

::v-deep .ant-radio-button-wrapper-disabled {
  border-left-color: #d9d9d9;
  background-color: #f5f5f5;
}
::v-deep .ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:hover {
  background-color: #f5f5f5;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: #f5f5f5;
}

// 表單樣式
::v-deep main .main .info[data-v-59611bc4] .ant-input {
  background-color: #ffffff;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

@media (max-width: 640px) {
  ::v-deep .ant-form-item label {
    font-size: 20px;
  }

  ::v-deep .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    height: 40px;
  }
  ::v-deep .ant-select-selection--single .ant-select-selection__rendered {
    top: 50%;
    transform: translateY(-50%);
  }
  ::v-deep .ant-select-selection--single {
    height: 40px;
    cursor: pointer;
  }
  ::v-deep main .main .info .ant-input {
    font-size: 20px !important;
  }
}
::v-deep .ant-btn:hover,
.ant-btn:focus {
  border-color: transparent;
}
::v-deep .ant-input:hover,
.ant-input:focus {
  border-color: #fe880a;
}
::v-deep .ant-input:focus {
  border-color: #fe880a;
}
::v-deep .ant-select-selection:hover,
.ant-select-selection:focus,
.ant-input:focus {
  border-color: #fe880a;
}
::v-deep .ant-btn:active,
.ant-btn.active {
  border-color: none !important;
}
</style>
