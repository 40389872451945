<template>
  <section id="cover-view">
    <v-parallax dark :src="data.coverImg" height="750">
      <v-row align="center" justify="start">
        <v-col cols="12">
          <a-row align="middle" type="flex" justify="space-around">
            <a-col :span="11" style="display: flex; align-items: center">
              <img :src="data.newImg" style="width: 100%; margin-left: 20px" />
            </a-col>
            <a-col :span="13" style="padding-left: 40px">
              <h1 class="com-title mb-4 wow animate__animated animate__fadeInUp" style="color: #fff">
                {{ data.title }}
              </h1>
              <v-row align="center" justify="start">
                <v-col data-wow-duration="3s" cols="12" xl="12" md="8" sm="6" style="color: #fff" class="sub-title font-weight-light wow animate__animated animate__fadeInUp">
                  {{ data.subTitle }}
                </v-col>
              </v-row>
              <v-row align="center" justify="start">
                <v-col data-wow-duration="6s" cols="12" xl="12" md="8" sm="6" class="content wow animate__animated animate__fadeInUp">
                  {{ data.content }}
                </v-col>
              </v-row>
              <v-btn
                rounded
                outlined
                large
                dark
                :style="{
                  'background-color': data.bottomColor,
                  'border-color': data.bottomColor,
                }"
                @click="goto('https://66.training/admin/login')"
                class="mt-10"
              >
                <span class="" style="font-size: 16px">
                  {{ $t("field.login") }}
                </span>
              </v-btn>
              <v-btn
                v-if="demoId != 6"
                rounded
                outlined
                large
                dark
                @click="ifHome()"
                :style="{
                  'background-color': data.bottomColor,
                  'border-color': data.bottomColor,
                }"
                class="mt-10 ml-5"
              >
                <span class="" style="font-size: 16px"> {{ data.btnText }} </span>
              </v-btn>
            </a-col>
            <a-col :span="24" class="hidden-sm-and-down"> </a-col>
          </a-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/bottomWaves.png" />
      </div>
    </v-parallax>

    <div class="svg-border-waves">
      <img :src="data.bottomCover" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {},
  props: {
    data: Object,
    demoId: String | Number,
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      // The player is playing a video.
    },
    change() {},
    stop() {
      this.player.stopVideo();
    },
    gotoNew(url) {
      window.open(url, "_blank");
    },
    goto(url) {
      window.location.href = url;
    },
    pause() {
      this.player.pauseVideo();
    },
    ifHome() {
      this.$router.push("/leave_message");
      // let _this = this;
      // console.log(111, this.$route, this.$route.fullPath == "/");
      // // if (_this.data.title == _this.$t("field.lelePlatform")) {
      // //   this.$vuetify.goTo("#features");
      // // } else {
      // //   gotoNew("https://66.training/admin/register");
      // // }
      // // 按市場部需求教培改成首頁，教培的按鈕先跳到“留言”，后管理端註冊
      // // if (this.$route.fullPath == "/") {
      // //   this.$vuetify.goTo("#features");
      // // } else {
      // //   this.gotoNew("https://66.training/admin/register");
      // // }
      // this.gotoNew("https://66.training/admin/register");
      // return _this.data.title == _this.$t("field.lelePlatform");
    },
  },
  mounted() {
    new this.$wow.WOW().init();
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
#cover-view {
  // direction: rtl;
  .circle {
    stroke: white;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 0.3;
  }
  #features {
    padding: 20px 0;
  }
  .playBut {
    /*  border: 1px solid red;*/
    display: inline-block;
    -webkit-transition: all 0.5s ease;

    .triangle {
      -webkit-transition: all 0.7s ease-in-out;
      stroke-dasharray: 240;
      stroke-dashoffset: 480;
      stroke: white;
      transform: translateY(0);
    }

    &:hover {
      .triangle {
        stroke-dashoffset: 0;
        opacity: 1;
        stroke: white;
        animation: nudge 0.7s ease-in-out;

        @keyframes nudge {
          0% {
            transform: translateX(0);
          }
          30% {
            transform: translateX(-5px);
          }
          50% {
            transform: translateX(5px);
          }
          70% {
            transform: translateX(-2px);
          }
          100% {
            transform: translateX(0);
          }
        }
      }

      .circle {
        stroke-dashoffset: 0;
        opacity: 1;
      }
    }
  }

  .btn-play {
    transition: 0.2s;
  }

  .svg-border-waves .v-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    overflow: hidden;
  }

  #hero {
    z-index: 0;
  }
  .svg-border-waves img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -2px;
    z-index: -1;
  }
  .v-application .elevation-4 {
    box-shadow: none !important;
  }
  .card {
    min-height: 300px;
    padding: 10px;
    transition: 0.5s ease-out;
    border: none !important;
  }

  .card .v-image {
    margin-bottom: 15px;
    transition: 0.75s;
  }

  .card h1 {
    margin-bottom: 10px;
  }

  .zoom-efect {
    transform: scale(1.1);
  }

  .up {
    transform: translateY(-20px);
    transition: 0.5s ease-out;
  }

  .r-btn {
    background: linear-gradient(139deg, #fbdc79 0%, #ff5965 100%);
    box-shadow: 0px 5px 10px 0px rgba(254, 140, 109, 0.35);
  }
  section {
    position: relative;
  }
}
@media (max-width: 640px) {
  .com-title {
    font-size: 35px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  }
  .sub-title {
    margin-top: 20px;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    float: right;
  }
  .content {
    margin-top: 20px;
    font-size: 20px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    font-weight: 300 !important;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    float: right;
  }
}
@media (min-width: 640px) {
  .com-title {
    font-size: 40px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    // float: right;
  }
  .sub-title {
    margin-top: 20px;
    font-size: 30px;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    float: right;
  }
  .content {
    margin-top: 20px;
    font-size: 24px;
    font-weight: 300 !important;
    font-family: MicrosoftYaHei;
    color: #ffffff;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    float: right;
  }
}
</style>
