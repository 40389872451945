var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"cover-view"}},[_c('v-parallax',{attrs:{"dark":"","src":_vm.data.coverImg,"height":"750"}},[_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('a-row',{attrs:{"align":"middle","type":"flex","justify":"space-around"}},[_c('a-col',{staticStyle:{"display":"flex","align-items":"center"},attrs:{"span":11}},[_c('img',{staticStyle:{"width":"100%","margin-left":"20px"},attrs:{"src":_vm.data.newImg}})]),_c('a-col',{staticStyle:{"padding-left":"40px"},attrs:{"span":13}},[_c('h1',{staticClass:"com-title mb-4 wow animate__animated animate__fadeInUp",staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{staticClass:"sub-title font-weight-light wow animate__animated animate__fadeInUp",staticStyle:{"color":"#fff"},attrs:{"data-wow-duration":"3s","cols":"12","xl":"12","md":"8","sm":"6"}},[_vm._v(" "+_vm._s(_vm.data.subTitle)+" ")])],1),_c('v-row',{attrs:{"align":"center","justify":"start"}},[_c('v-col',{staticClass:"content wow animate__animated animate__fadeInUp",attrs:{"data-wow-duration":"6s","cols":"12","xl":"12","md":"8","sm":"6"}},[_vm._v(" "+_vm._s(_vm.data.content)+" ")])],1),_c('v-btn',{staticClass:"mt-10",style:({
                  'background-color': _vm.data.bottomColor,
                  'border-color': _vm.data.bottomColor,
                }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.goto('https://66.training/admin/login')}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.$t("field.login"))+" ")])]),(_vm.demoId != 6)?_c('v-btn',{staticClass:"mt-10 ml-5",style:({
                  'background-color': _vm.data.bottomColor,
                  'border-color': _vm.data.bottomColor,
                }),attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.ifHome()}}},[_c('span',{staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.data.btnText)+" ")])]):_vm._e()],1),_c('a-col',{staticClass:"hidden-sm-and-down",attrs:{"span":24}})],1)],1)],1),_c('div',{staticClass:"svg-border-waves text-white"},[_c('v-img',{attrs:{"src":require("@/assets/img/bottomWaves.png")}})],1)],1),_c('div',{staticClass:"svg-border-waves"},[_c('img',{attrs:{"src":_vm.data.bottomCover}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }