<template>
  <div
    class="home-illustrate-item"
    :style="{
      '--flex-direction': flexDirection,
      'background-color': coverColor,
    }"
  >
    <div
      class="bg-img flex-1 wow animate__animated"
      :class="animationEntry != 'animate__fadeInUp' ? animationEntry : ''"
    >
      <img :src="image" class="img" />
    </div>
    <div class="content-desc flex-1">
      <div class="content-desc-body">
        <div class="wow animate__animated com-title" :class="animationEntry">
          {{ title }}
        </div>
        <p
          v-for="(desc, i) of descs"
          class="desc wow animate__animated"
          :class="animationEntry"
          data-wow-duration="2s"
        >
          {{ desc }}
        </p>
        <div v-for="(item, i) of features" :key="i" class="feature">
          <div>
            <v-row>
              <v-col
                cols="24"
                :data-wow-duration="2 + i++ + 's'"
                class="d-flex justify-start mb-4 wow animate__animated"
                :class="animationEntry"
                :style="{
                  'margin-bottom': item.title ? '10px' : '0',
                  'align-items': alignValue,
                }"
              >
                <v-img :src="item.icon" max-width="60px" class="mr-4" />
                <span class="text-justify">
                  <div
                    v-if="item.title"
                    style="margin-bottom: 10px; color: #2d3f50"
                    class="iconDesc"
                  >
                    {{ item.title }}
                  </div>
                  <div class="iconContent">
                    {{ item.content }}
                  </div>
                </span>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    descs: {
      type: Array,
    },
    features: {
      type: Array,
    },
    direction: {
      type: String,
      default: "LTR" | "RTL",
    },
    coverColor: {
      type: String,
      default: "#ffffff",
    },
    animationEntry: {
      type: String,
      default: "animate__fadeInUp",
    },
    alignValue: {
      type: String,
      default: "flex-start",
    },
  },
  methods: {
    ready(event) {
      this.player = event.target;
    },
    playing(event) {
      // The player is playing a video.
    },
    change() {
      console.log(11, this.flexDirection);
    },

    goto(url) {
      window.location.href = url;
    },
  },
  computed: {
    flexDirection() {
      return this.direction === "LTR" ? "row" : "row-reverse";
    },
  },
  mounted() {
    new this.$wow.WOW().init();
  },
};
</script>

<style lang="scss" scoped>
.v-application .align-center {
  // align-items: start !important;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-col {
  flex-direction: column;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.bg-img {
  flex: 1 1 0%;
  .img {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    -o-object-fit: cover;
    object-fit: cover;
  }
}
@media (max-width: 640px) {
  .home-illustrate-item {
    flex-direction: column;
    // padding: 8px 0;
    width: 100%;
    .content-desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 15px;
      .com-title {
        font-size: 30px;
        color: #2d3f50;
        // font-family: MicrosoftYaHei !important;
      }
      .desc {
        margin-top: 10px;
        font-size: 20px;
        // font-size: 30px;
        color: #333;
        font-weight: 500;
      }
      .iconDesc {
        font-size: 20px;
      }
      .iconContent {
        font-size: 18px;
      }

      .feature {
        display: flex;
        align-items: center;
        color: #666;
        margin-top: 20px;

        .svg-icon {
          width: 30px !important;
          height: 30px !important;
          margin-right: 15px;
        }
      }
    }
  }
}

@media (min-width: 640px) {
  .home-illustrate-item {
    display: flex;
    flex-direction: var(--flex-direction);
    width: 100%;
    height: auto;
    .content-desc {
      flex: 1 1 0%;
      .com-title {
        font-size: 50px;
        // font-family: MicrosoftYaHei !important;
        color: #2d3f50;
      }
      .content-desc-body {
        padding: auto 0;
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        height: 100%;

        h1 {
          font-size: 50px;
          color: #2d3f50;
        }

        .desc {
          margin-top: 30px;
          color: #333;
          font-family: MicrosoftYaHei;
          font-size: 26px;
        }
        .iconDesc {
          font-size: 26px;
        }
        .iconContent {
          font-size: 20px;
        }
        .feature {
          display: flex;
          margin-top: 20px;
          color: #666;
          font-family: MicrosoftYaHei;
          font-size: 26px;

          .svg-icon {
            flex-shrink: 0;
            width: 91px !important;
            height: 91px !important;
            margin-right: 37px;
          }
        }
      }
    }
  }
}
</style>
